import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import { inferNextRoute } from '@slices/routeSlice';
import { Link, navigate } from 'gatsby';
import { Heading, Flex, Para, Box, Button, Label } from 'workspace-core-ui';
import Layout from '@containers/Layout';
import BodyWrapper from '@components/BodyWrapper';
import { setHeaderType } from '@slices/gameStateSlice';
import useTranslation from '@hooks/useTranslation';
import useSound from '@hooks/useSound';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import ControlCenter, {
  ControlResponse,
} from '@components/controls/ControlCenter';
import { Interrupt, Content, ControlData, Claim } from 'types';
import { logItem } from '@slices/loggingSlice';
import Seo from '@containers/Seo';
import useNavigateLog from '@hooks/useNavigateLog';
import getSizedParaVariant from '@utils/getSizedParaVariant';

interface Props {
  pageContext: {
    url: string;
    isFollowup?: boolean;
    screenData: Interrupt<Content, ControlData<Content[]>> &
      Claim<Content, ControlData<Content[]>>;
  };
}

// const StyledLink = styled(Para).attrs({ as: Link, variant: 'p3' })(
//   css({ textDecoration: 'underline', fontWeight: '500' }),
// );

const InterruptScreen = (props: Props) => {
  const [startTimestamp, setStartTimestamp] = useState<number>();
  const { playSound } = useSound();
  const [submittedAnswer, setSubmittedAnswer] =
    useState<ControlResponse | undefined>(undefined);
  const { nextRoute } = useAppSelector(state => state.route);
  const dispatch = useAppDispatch();
  const {
    Interrupt_Text,
    Claim_Text = null,
    Controls,
    // this blurb is used for followups, you get these when you answer incorrectly
    Incorrect_Text,
  } = props.pageContext?.screenData;

  const { url, isFollowup } = props.pageContext;
  const { t, g } = useTranslation();
  const backgroundColor = isFollowup ? 'secondary' : 'background';
  const bodyText = isFollowup ? Incorrect_Text : Interrupt_Text;

  const normalizedQuestionName = isFollowup
    ? Claim_Text?.Content_Type || ''
    : Interrupt_Text?.Content_Type || '';

  useNavigateLog({
    questionName: normalizedQuestionName,
  });

  useLayoutEffect(() => {
    // record when user officially "sees" the question
    setStartTimestamp(Date.now());
  }, []);

  useEffect(() => {
    dispatch(setHeaderType({ headerType: 'minimal' }));
    dispatch(inferNextRoute({ compareAgainst: url }));
  }, [dispatch, url]);

  // TODO: mb this can be a hook
  useEffect(() => {
    if (submittedAnswer) {
      const endTimestamp = Date.now();
      // TODO: unify control center returns, so we can submit agnostically
      // dispatch(questionHasBeenAnswered({ submittedAnswer }));
      dispatch(
        logItem({
          question_name: normalizedQuestionName,
          question_type:
            `${Controls?.Control_Type}${isFollowup ? '-followup' : ''}` || '',
          // interrupts have no "results" but we still need to provide one for schema to work
          result: '',
          collection_name: 'answers',
          answer_text: submittedAnswer.controlValue.toString(),
          duration_in_seconds: startTimestamp
            ? (endTimestamp - startTimestamp) / 1000
            : 0,
        }),
      );

      // NOTE: this is done because binary questions do not "autoroute" like likerts. Likerts contain the href to the next entry in and of themselves
      if (
        Controls?.Control_Type === 'Binary' ||
        Controls?.Control_Type === 'Unary'
      ) {
        navigate(nextRoute?.url || '/', { replace: true });
      }
    }
    // ignore exhaustive check for startDate as we don't care if it updates or not (atleast not in terms of rendering)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, nextRoute?.url, submittedAnswer]);

  return (
    <Layout backgroundColor={backgroundColor} data-cy="interrupt">
      <Seo />
      <BodyWrapper p={5}>
        <Flex flex={0.5} />
        <Flex
          flexDirection="column"
          flex={0.5}
          alignItems="center"
          minWidth="50vmax"
          maxWidth="1100px"
          alignSelf="center"
        >
          <Box p={5} width="100%">
            <Heading variant="h2" mb={4}>
              <CustomMdxRenderer>
                {g(Controls?.Header_Text, true)}
              </CustomMdxRenderer>
            </Heading>
            <Para
              as="span"
              sx={{
                // similar treatment used in ResultsView
                '.gatsby-resp-image-wrapper': {
                  width: '300px',
                  m: 3,
                },
              }}
              variant={getSizedParaVariant(g(bodyText))}
            >
              <CustomMdxRenderer>
                {/* it can be the claim blurb if this interrupt is a followup */}
                {g(bodyText, true)}
              </CustomMdxRenderer>
            </Para>
          </Box>
        </Flex>
        <Flex mt="auto" mb={6} flexDirection="column" justifyContent="center">
          <ControlCenter
            typeOfControl={Controls?.Control_Type}
            setSubmittedAnswer={e => {
              playSound('Button');
              setSubmittedAnswer(e);
            }}
            possibleAnswers={Controls?.Possible_Answers}
            to={nextRoute?.url || '/'}
          />
          <Button
            buttonSize="medium"
            variant="ghost"
            as={Link}
            onPress={() => {
              const endTimestamp = Date.now();
              playSound('Button');
              dispatch(
                logItem({
                  question_name: normalizedQuestionName,
                  question_type:
                    `${Controls?.Control_Type}${
                      isFollowup ? '-followup' : ''
                    }` || '',
                  // interrupts have no "results" but we still need to provide one for schema to work
                  result: '',
                  collection_name: 'answers',
                  answer_text: 'skip',
                  duration_in_seconds: startTimestamp
                    ? (endTimestamp - startTimestamp) / 1000
                    : 0,
                }),
              );
            }}
            mt={7}
            mx="auto"
            width="min-content"
            to={nextRoute?.url || '/'}
            replace
          >
            <Label textDecoration="underline" variant="l3">
              {t('Skip Button')}
            </Label>
          </Button>
        </Flex>
      </BodyWrapper>
    </Layout>
  );
};

export default InterruptScreen;
